<template>
    <div class="side-nav uk-animation-slide-left-medium shadow">
            <div class="side-nav-bg"></div>
            <!-- logo -->
            <div class="logo uk-visible@s">
                <a :href="`${$baseURL}`">
                    <img :src="`${$baseURL}images/logo.png`" alt="image">
                </a>
            </div>
            <ul>
                <li class="text-center mt-1 close-sidebar">
                    <button class="btn-sm btn-dark" @click="closeSideBar">X</button>
                </li>
                <li>
                    <a href="/"> <i class="icon-material-outline-home"></i> <span class="tooltips"> Home</span></a>
                </li>
                <li>
                    <router-link to="/user/dashboard"> <i class="icon-material-outline-dashboard"></i> <span class="tooltips"> Dashboard</span></router-link>
                </li>
                <li>
                    <a href="#"> <i class="uil-play-circle"></i> </a>
                    <div class="side-menu-slide">
                        <div class="side-menu-slide-content">
                            <ul data-simplebar>
                                <li v-for="(data, index) of categories" :key="index">
                                    <router-link class="active" :to="`/user/category/${data.id}/course`"> 
                                        <i class="icon-material-outline-arrow-forward"></i> {{ data.title }} 
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                
                <!-- <li>
                    <a href="#" uk-toggle="target: #searchbox; cls: is-active"><i class="uil-search-alt"></i></a>
                </li> -->
                                
                <li>
                    <router-link to="/user/profile"> <i class="icon-material-outline-settings"></i> <span class="tooltips"> Account Settings</span></router-link>
                </li>
                <li>
                    <router-link to="/user/favourite-courses"> <i class="icon-material-outline-favorite"></i> <span class="tooltips"> Favourite Courses</span></router-link>
                </li>
                <li>
                    <router-link to="/user/change-password"> <i class="icon-feather-lock"></i> <span class="tooltips">Change Password</span></router-link>
                </li>
                <li>
                    <router-link to="/user/bank-details"> <i class="icon-feather-dollar-sign"></i> <span class="tooltips">Bank Details</span></router-link>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="userLogout"> <i class="icon-feather-log-out"></i> <span class="tooltips"> Logout</span></a>
                </li>
            </ul>
        </div>
</template>

<script>
export default {
    data(){
        return {
            categories: []
        }
    },
    methods: {   
        closeSideBar(){
            if(document.querySelector('#wrapper').classList.contains('mobile-active')){
                document.querySelector('#wrapper').classList.remove('mobile-active');
            }
        },     
        courseCategories(){
            let params = {
                'paginate': 'all'
            }
            this.$store.dispatch('userCourseCategories', {params}).then(res=>{
                if(res.data.status === true)
                    this.categories = res.data.data
            })
            .catch(error => console.log('Error:' +error.response.data.error))
        },
        userLogout(){
            this.$store.dispatch('logout').then(res=>{
                window.location.href = '/'
            })
            .catch(error => console.log('Error:' +error.response.data.error))
        }
    },
    created: function(){
        this.courseCategories()
        
    }
}
</script>